import { PaymentChannelModel, TopUpTransactionDetailModel, detail } from '@app/api/topUp.api';
import { useEffect, useState } from 'react';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { getRoleUser } from '@app/services/localStorage.service';
import { useTranslation } from 'react-i18next';
import * as S from '@app/components/nft-dashboard/Balance/components/TopUpBalanceForm/TopUpBalanceForm.styles';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { Input, InputNumber } from 'antd';
import { TopUpPaymentCard } from '@app/components/nft-dashboard/Balance/components/TopUpPaymentCard/TopUpPaymentCard';
import { TopUpApproveFormData } from '../BillingPage';
import useCurrentRole from '@app/hooks/useCurrentRole';

interface TopUpDetailModalProps {
  uuid: string;
  cards: PaymentChannelModel[];
  loading: boolean;
  onFinish: (values: TopUpApproveFormData) => void;
}

export const TopUpDetailModal: React.FC<TopUpDetailModalProps> = ({ uuid, cards, loading, onFinish }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const [channelIdx, setPaymentChannel] = useState<number>(0);
  const [orderDetail, setOrderDetail] = useState<TopUpTransactionDetailModel>();
  const { isAdmin } = useCurrentRole();

  useEffect(() => {
    detail(uuid).then((res) => {
      setOrderDetail(res);
      form.setFieldValue('uuid', uuid);
      cards.forEach((card, idx) => {
        if (card.name.toLocaleUpperCase() == res.paymentChannel) {
          setPaymentChannel(idx);
        }
      });
    });
  }, [cards, form, uuid]);

  return (
    <BaseForm form={form} name="topUpBalance" onFinish={onFinish} requiredMark="optional" validateTrigger="onBlur">
      <S.ContentWrapper>
        <S.BlockWrapper>
          <S.Label>{t('topup.paymentChannel')}</S.Label>
          <BaseRadio.Group disabled={true} value={channelIdx}>
            {cards.map((card, idx) => {
              const paymentCard: string = card.name.toUpperCase();

              return (
                <BaseRadio.Button key={paymentCard} value={idx}>
                  <TopUpPaymentCard img={card.iconUri} type={paymentCard} />
                </BaseRadio.Button>
              );
            })}
          </BaseRadio.Group>
          <S.Label> {t('topup.amount')}</S.Label>
          <InputNumber readOnly={true} width={100} value={orderDetail?.amount} type="number" addonBefore={<>USD</>} />
          <S.Label> {t('topup.transactionId')}</S.Label>
          <Input readOnly={true} value={orderDetail?.transactionId} />
          <S.Label> {t('topup.evidence')}</S.Label>
          <img src={orderDetail?.evdUrl} />
        </S.BlockWrapper>

        {isAdmin && (
          <>
            <BaseForm.Item name={'uuid'}></BaseForm.Item>
            <BaseForm.Item name={'status'}>
              <BaseRow gutter={[10, 10]}>
                <BaseCol xs={12}>
                  <BaseButton
                    type="primary"
                    block
                    htmlType="submit"
                    disabled={loading}
                    danger
                    onClick={() => form.setFieldValue('status', 'REJECT')}
                  >
                    {t('topup.reject')}
                  </BaseButton>
                </BaseCol>
                <BaseCol xs={12}>
                  <BaseButton
                    type="primary"
                    block
                    htmlType="submit"
                    disabled={loading}
                    onClick={() => form.setFieldValue('status', 'APPROVED')}
                  >
                    {t('topup.approve')}
                  </BaseButton>
                </BaseCol>
              </BaseRow>
            </BaseForm.Item>
          </>
        )}
      </S.ContentWrapper>
    </BaseForm>
  );
};
