import React from 'react';
import AdminProductPage from './AdminPage';
import SellerPage from './SellerPage';
import useCurrentRole from '@app/hooks/useCurrentRole';

const ProductPage: React.FC = () => {
  const { isAdmin, isSupport } = useCurrentRole();

  return <>{isAdmin || isSupport ? <AdminProductPage /> : <SellerPage />}</>;
};

export default ProductPage;
