import {
  ApproveOrder,
  CancelOrder,
  EditFees,
  UpdateOrder,
  detailOrder,
  detailOrderProduct,
  getDetailOrder,
  trackingUrls,
  updateOrderRequest,
  updateProductOrderRequest,
} from '@app/api/order.api';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { useMounted } from '@app/hooks/useMounted';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { getCurrencyPrice } from '@app/utils/utils';
import { CurrencyTypeEnum } from '@app/interfaces/interfaces';
import * as S from '@app/components/orders/Orders.styles';
import { useResponsive } from '@app/hooks/useResponsive';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { References } from '@app/components/common/References/References';
import { TrackingCard } from '@app/components/orders/workflow/TrackingCard';
import InfoOrdersDetail from '../workflow/InfoOrdersDetail';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { DetailName, TitleText } from './Detail.styles';
import FormOrdersDetail from '../workflow/FormOrdersDetail';
import ProductOrdersDetail from '../workflow/ProductOrdersDetail';
import { TotalCard } from '../workflow/TotalCard';
import { ApiErrorData } from '@app/api/http.api';
import EditOrderModal from '../workflow/EditOrderModal';
import FormEditFees from '../workflow/FormEditFees';
import useCurrentRole from '@app/hooks/useCurrentRole';

const listStatus = [
  'PENDING',
  'CONFIRM_PENDING',
  'CONFIRMED',
  'REPLACE_PENDING',
  'REPLACED',
  'IN_PRODUCTION',
  'SHIPPED',
  'IN_TRANSIT',
  'DELIVERED',
  'CANCEL_PENDING',
  'CANCELED',
  'REFUND_PENDING',
  'REFUNDED',
];
const checkStatus: { [key: string]: number } = {
  DELIVERED: 3,
  RETURNED: 10,
  CANCELED: 11,
  REFUNDED: 10,

  IN_PRODUCTION: 6,
  SHIPPED: 7,
  IN_TRANSIT: 8,

  CONFIRMED: 2,
  REPLACED: 5,

  CONFIRM_PENDING: 1,
  REFUND_PENDING: 9,
  REPLACE_PENDING: 4,
  CANCEL_PENDING: 10,

  PENDING: 0,
};
const initialData: detailOrder = {
  uuid: '',
  source: '',
  ownerUuid: '',
  ownerEmail: '',
  orderId: '',
  status: '',
  total: 0,
  shippingFees: 0,
  createdDate: '',
  createdBy: '',
  updatedDate: '',
  updatedBy: '',
  shippingName: '',
  shippingAddress: '',
  shippingPhone: '',
  trackingId: '',
  shippingCarrier: '',
  trackingLabel: '',
  products: [] as detailOrderProduct[],
  designFee: 0,
};

const LayoutDetailPage: React.FC = () => {
  const { isAdmin, isSeller, isFactory, isSupport } = useCurrentRole();

  const { isMounted } = useMounted();
  const { uuid } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [detail, setDetailOrder] = useState<detailOrder>(initialData);
  const [tableData, setTableData] = useState<{ data: detailOrderProduct[] }>({ data: [] });
  const [status, setStatus] = useState<string[]>(listStatus);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [isModalEditFeeOpen, setModalEditFeeOpen] = useState<boolean>(false);
  const [initFormData, setInitFormData] = useState<updateOrderRequest>({
    products: [],
    shippingAddress: '',
    shippingName: '',
    uuid: '',
  });

  const handleModal = () => {
    setModalOpen((open) => !open);
  };

  const handleEditModal = () => {
    setEditModalOpen((open) => !open);
  };

  const handleModalEditFeeOpen = () => {
    setModalEditFeeOpen((open) => !open);
  };

  const fetch = useCallback(
    async (uuid: string) => {
      setLoading(true);
      try {
        const res = await getDetailOrder(uuid);
        console.log('res: ', res);
        if (isMounted.current) {
          setDetailOrder({
            ...res,
            updatedDate: new Date(res.updatedDate).toLocaleString(),
            createdDate: new Date(res.createdDate).toLocaleString(),
            total: getCurrencyPrice(res.total, CurrencyTypeEnum.USD),
          });
          setTableData((tableData) => ({
            ...tableData,
            data: res.products.map((item: detailOrderProduct, index: number) => {
              const newItem = {
                ...item,
                no: index + 1,
                product: item.productSku,
                position: (
                  <div style={{ maxHeight: '7rem', overflowY: 'auto' }} key={index}>
                    {item.positions?.length > 0 ? <hr /> : ''}
                    {item.positions?.map((pos, ind) => {
                      return (
                        <>
                          <S.GeneralInfoTable key={ind}>
                            <tbody>
                              <tr>
                                <th>Position:</th>
                                <td>{pos.position}</td>
                              </tr>
                              <tr>
                                <th>Design:</th>
                                <td>{pos.design}</td>
                              </tr>
                              <tr>
                                <th>Type:</th>
                                <td>{pos.type}</td>
                              </tr>
                            </tbody>
                          </S.GeneralInfoTable>
                          <hr />
                        </>
                      );
                    })}{' '}
                  </div>
                ),
              };
              return newItem;
            }),
          }));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [isMounted],
  );

  useEffect(() => {
    if (uuid) {
      fetch(uuid);
    }
  }, [fetch, uuid]);

  useEffect(() => {
    switch (detail.status) {
      case 'PENDING':
        setStatus(isSupport ? ['CONFIRM_PENDING', 'CANCEL_PENDING'] : isAdmin ? listStatus : []);
        break;
      case 'CONFIRM_PENDING':
        setStatus(isAdmin ? listStatus : []);
        break;
      default:
        if (isSupport) {
          const status = ['REFUND_PENDING', 'REPLACE_PENDING', 'CANCEL_PENDING'].filter(
            (s: string) => checkStatus[detail.status] < checkStatus[s],
          );
          setStatus(status);
        }
        if (isFactory) {
          const status = ['IN_PRODUCTION', 'SHIPPED'].filter(
            (s: string) => checkStatus[detail.status] < checkStatus[s],
          );
          setStatus(status);
        }
        if (isAdmin) {
          setStatus(listStatus);
        }
        break;
    }
  }, [detail, isAdmin, isFactory, isSupport]);

  useEffect(() => {
    if (detail) {
      initFormData.uuid = detail.uuid;
      initFormData.shippingName = detail.shippingName;
      initFormData.shippingAddress = detail.shippingAddress;
      initFormData.shippingPhone = detail.shippingPhone;
      initFormData.trackingId = detail.trackingId || undefined;
      initFormData.trackingLabel = detail.trackingLabel;
      initFormData.products = [];
      detail.products.forEach((product) =>
        initFormData.products.push({
          productSku: product.productSku,
          quantity: product.quantity,
          color: product.color.name,
          size: product.size,
          positions: product.positions,
          note: product.note,
        }),
      );

      setInitFormData({
        uuid: detail.uuid,
        shippingName: detail.shippingName,
        shippingAddress: detail.shippingAddress,
        shippingPhone: detail.shippingPhone,
        trackingId: detail.trackingId || undefined,
        trackingLabel: detail.trackingLabel,
        products: detail.products.map<updateProductOrderRequest>((product: detailOrderProduct) => {
          return {
            productSku: product.productSku,
            quantity: product.quantity,
            color: product.color.name,
            size: product.size,
            positions: product.positions,
            note: product.note,
          };
        }),
      });
    }
  }, [detail]);

  const handleSubmit = async (values: detailOrder) => {
    setLoading(true);
    try {
      if (!detail || (!values.status && isSeller)) {
        notificationController.info({
          message: `Order don't Update!`,
          description: 'Can not update!',
        });
        return;
      }
      ApproveOrder(detail.uuid, {
        status: values.status || detail.status,
        trackingId: values.trackingId?.replace(trackingUrls, '') || detail.trackingId || '',
        shippingCarrier: values.shippingCarrier || detail.shippingCarrier || '',
      })
        .then((res) => {
          console.log(res);
          notificationController.success({
            message: 'Update Order!',
            description: 'Order has successfully updated.',
          });
          fetch(detail.uuid);
          setModalOpen(false);
        })
        .catch((error: ApiErrorData) => {
          console.log(error);
          notificationController.error({
            message: 'Update Order!',
            description: error.message,
          });
        });
    } catch (error) {
      console.log(error);
      notificationController.error({
        message: 'Update Order!',
        description: 'Order update failed',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditSubmit = async (values: any) => {
    console.log(values);
    setLoading(true);
    try {
      UpdateOrder(detail.uuid, values)
        .then((res) => {
          console.log(res);
          notificationController.success({
            message: 'Update Order!',
            description: 'Order has successfully updated.',
          });
          fetch(detail.uuid);
          setModalOpen(false);
        })
        .catch((error: ApiErrorData) => {
          console.log(error);
          notificationController.error({
            message: 'Update Order!',
            description: error.message,
          });
        });
    } catch (error) {
      console.log(error);
      notificationController.error({
        message: 'Update Order!',
        description: 'Order update failed',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancelOrder = (uuid: string) => {
    setLoading(true);
    CancelOrder(uuid)
      .then(() => {
        notificationController.success({
          message: 'Cancel Order!',
          description: 'Order has successfully updated.',
        });
        fetch(detail.uuid);
      })
      .catch((error: ApiErrorData) => {
        console.log(error);
        notificationController.error({
          message: 'Cancel Order!',
          description: error.message,
        });
      })
      .finally(() => setLoading(false));
  };

  const handleEditFeesSubmit = (value: any) => {
    setLoading(true);
    const data = {
      ...value,
      uuid: detail.uuid,
    };
    EditFees(detail.uuid, data)
      .then(() => {
        notificationController.success({
          message: 'Edit Fees!',
          description: 'Order has successfully updated.',
        });
        fetch(detail.uuid);
      })
      .catch((error) =>
        notificationController.error({
          message: 'Edit Fees!',
          description: error.message,
        }),
      )
      .finally(() => setLoading(false));
  };

  const { isDesktop } = useResponsive();

  return (
    <>
      <BaseSpin spinning={loading}>
        {isDesktop ? (
          <BaseRow>
            <S.LeftSideCol xl={15} xxl={16} id="desktop-content">
              <BaseRow gutter={[60, 60]}>
                <BaseCol span={24}>
                  <InfoOrdersDetail
                    handleCancelOrder={handleCancelOrder}
                    setModalOpen={setModalOpen}
                    setEditModalOpen={setEditModalOpen}
                    setModalEditFeeOpen={setModalEditFeeOpen}
                    detail={detail}
                    checkStatus={checkStatus}
                    status={status}
                  />
                </BaseCol>
                <BaseCol span={24}>
                  <TitleText level={2}>Products</TitleText>
                  <S.Space></S.Space>
                  <ProductOrdersDetail
                    data={tableData.data}
                    isUpload={checkStatus[detail.status] <= checkStatus.CONFIRMED}
                  />
                </BaseCol>
              </BaseRow>
              {/* <References /> */}
            </S.LeftSideCol>

            <S.RightSideCol xl={9} xxl={8}>
              <div id="tracking">
                <TrackingCard status={detail.status} trackingId={detail.trackingId || ''}></TrackingCard>
              </div>
              <S.Space />
              <S.Space />
              <TotalCard
                total={detail.total}
                shippingFees={detail.shippingFees}
                products={detail.products}
                hasHandlingFee={detail.trackingLabel != null && detail.trackingLabel != undefined}
              />
            </S.RightSideCol>
          </BaseRow>
        ) : (
          <BaseRow gutter={[20, 24]}>
            <BaseCol span={24}>
              <div id="tracking">
                <TrackingCard status={detail.status} trackingId={detail.trackingId || ''}></TrackingCard>
              </div>
              <S.Space />
              <div id="total">
                {!isFactory && (
                  <TotalCard
                    total={detail.total}
                    shippingFees={detail.shippingFees}
                    products={detail.products}
                    hasHandlingFee={detail.trackingLabel != null && detail.trackingLabel != undefined}
                  />
                )}
              </div>
            </BaseCol>

            <BaseCol span={24}>
              <InfoOrdersDetail
                handleCancelOrder={handleCancelOrder}
                setModalOpen={setModalOpen}
                setEditModalOpen={setEditModalOpen}
                setModalEditFeeOpen={setModalEditFeeOpen}
                detail={detail}
                checkStatus={checkStatus}
                status={status}
              />
            </BaseCol>
            <BaseCol span={24}>
              <BaseCard padding={0} style={{ padding: '2rem 2.5rem' }}>
                <DetailName>Products</DetailName>
                <S.Space></S.Space>
                <ProductOrdersDetail
                  data={tableData.data}
                  isUpload={checkStatus[detail.status] <= checkStatus.CONFIRMED}
                />
              </BaseCard>
            </BaseCol>
          </BaseRow>
        )}
        <FormOrdersDetail
          handleSubmit={handleSubmit}
          detail={detail}
          checkStatus={checkStatus}
          status={status}
          isOpen={isModalOpen}
          onOpenChange={handleModal}
        />
        <EditOrderModal
          handleSubmit={handleEditSubmit}
          detail={detail}
          checkStatus={checkStatus}
          status={status}
          isOpen={isEditModalOpen}
          onOpenChange={handleEditModal}
          initData={initFormData}
        />
        <FormEditFees
          handleSubmit={handleEditFeesSubmit}
          detail={detail}
          isOpen={isModalEditFeeOpen}
          onOpenChange={handleModalEditFeeOpen}
        />
      </BaseSpin>
    </>
  );
};
export default LayoutDetailPage;
