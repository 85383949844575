import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { UserModel, UserInfoModel } from '@app/domain/UserModel';
import { persistUserInfo } from '@app/services/localStorage.service';

export interface UserState {
  user: UserModel | null;
}

export const initialState: UserState = {
  user: {
    uuid: '',
    avatar: '',
    fullName: '',
    email: '',
    roles: '',
    stores: [],
    lang: 'en',
    walletBalance: 0,
    socials: {
      twitter: '',
      linkedin: '',
      facebook: '',
    },
    address: {
      country: '',
      city: '',
      address1: '',
      address2: '',
      zipcode: '',
    },
  },
};

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', (newUser: UserModel) => {
  return {
    payload: newUser,
  };
});

export const setUserInfo = createAction<PrepareAction<UserInfoModel>>('user/setUserInfo', (newUserInfo) => {
  persistUserInfo(newUserInfo);

  return {
    payload: newUserInfo,
  };
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
  },
});

export default userSlice.reducer;
