import React, { useState } from 'react';
import * as S from '@app/components/orders/Orders.styles';
import {
  ReplaceLink,
  detailOrderProduct,
  getReplaceLink,
  updateOrderProductPosition,
  updateOrderProductPositionResponse,
} from '@app/api/order.api';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { TitleText } from '../orderDetail/Detail.styles';
import { BaseTooltip } from '@app/components/common/BaseTooltip/BaseTooltip';
import ModalEditMockup from './ModalEditMockup';
import { notificationController } from '@app/controllers/notificationController';
import { CopyOutlined, EyeOutlined, GlobalOutlined, UploadOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ColorItem } from '@app/components/catalog/conmon.styles';
import useCurrentRole from '@app/hooks/useCurrentRole';

type Props = {
  data: detailOrderProduct[];
  isUpload: boolean;
};

function getPositionText(position: string): string {
  switch (position) {
    case 'CENTER_CHEST':
      return 'Center Chest';
    case 'LEFT_CHEST':
      return 'Left Chest';
    case 'RIGHT_CHEST':
      return 'Right Chest';
    case 'LEFT_SLEEVE':
      return 'Left Sleeve';
    case 'RIGHT_SLEEVE':
      return 'Right Sleeve';
    case 'NECK':
      return 'Neck';
    case 'HAT_FRONT':
      return 'Hat Front';
    case 'HAT_BACK':
      return 'Hat Back';
    case 'HAT_SIDE':
      return 'Hat Side';
    case 'BACK':
      return 'Back';
    case 'ARM':
      return 'Arm';
    case 'RIGHT_ARM':
      return 'Right Arm';
    case 'LEFT_ARM':
      return 'Left Arm';
    case 'LEFT_SIDE':
      return 'Left Side';
    case 'RIGHT_SIDE':
      return 'Right Side';
    case 'NECK_BACK':
      return 'Neck/Back';
    default:
      return '-';
  }
}

function getTypeText(type: string): string {
  switch (type) {
    case 'EMBROIDERY':
      return 'Embroidery';
    case 'PRINTING':
      return 'Printing';
    case 'PUFF_PRINTING':
      return 'Puff Printing';
    case 'APPLIQUE':
      return 'Applique';
    case 'GLITTER_APPLIQUE':
      return 'Glitter Applique';
    case 'GLITTER_PRINTING':
      return 'Glitter Printing';
    case 'LEATHER_PATCH':
      return 'Leather Patch';
    case 'FABRIC_PATCH':
      return 'Fabric Patch';
    default:
      return '-';
  }
}

function ProductOrdersDetail({ data, isUpload }: Props) {
  const [isModalEditOpen, setModalEditpOpen] = useState<boolean>(false);
  const [modalEditType, setModalEditType] = useState<boolean>();
  const [modalEditDesignOpenDetail, setModalEditDesignOpenDetail] = useState<updateOrderProductPosition>();
  const [replaceLink, setReplaceLink] = useState<updateOrderProductPositionResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [tooltipTextMokcup, setTooltipTextMokcup] = useState<string>("Copy the seller's mockup file");
  const [tooltipTextDesign, setTooltipTextDesign] = useState<string>("Copy the seller's design file");
  const [orderDetailUuid, setOrderDetailUuid] = useState<string>();

  const { isSeller } = useCurrentRole();

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const body = {
        orderUuid: values.orderUuid,
        productSku: values.productSku,
        position: {
          position: values.position,
          design: values.design,
          type: values.type,
          mockupLink: values.mockupLink,
        },
        size: values.size,
        color: {
          hex: values.colorHex,
          name: values.colorName,
        },
        uuid: values.uuid,
      };
      ReplaceLink(body)
        .then(() => {
          notificationController.success({
            message: 'Order Updated!',
            description: 'Order has successfully updated.',
          });
          window.location.reload();
        })
        .catch((error) => {
          notificationController.error({
            message: `Order Don't Update!`,
            description: error,
          });
        });
      console.log(values);
    } finally {
      setLoading(false);
    }
  };

  const handleModalEditDesignOpen = () => {
    setModalEditpOpen((open) => !open);
  };

  return (
    <BaseRow gutter={[20, 24]}>
      {data.map((item, index) => {
        return (
          <BaseCol xs={24} key={(index + 1).toString()}>
            <BaseCard padding={'2rem'}>
              <TitleText level={1}>
                <a href={`/catalog/product/detail-product/${item.productUuid}`} target='"_blank"'>
                  {`${(index + 1).toString()}. ${item.name} - ${item.productSku}`}
                </a>
              </TitleText>
              <BaseRow gutter={[20, 12]}>
                <BaseCol xs={24} lg={8}>
                  <S.GeneralInfoTable>
                    <tbody>
                      <tr>
                        <th style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>Size:</th>
                        <td>{item.size}</td>
                      </tr>
                    </tbody>
                  </S.GeneralInfoTable>
                </BaseCol>
                <BaseCol xs={24} lg={8}>
                  <S.GeneralInfoTable>
                    <tbody>
                      <tr>
                        <th>Color:</th>
                        <td>
                          <ColorItem
                            key={item.color.hex}
                            className="item"
                            style={{ '--color': item.color.hex } as React.CSSProperties}
                          >
                            <span className="color-preview"></span>
                            {item.color.name}
                          </ColorItem>
                        </td>
                      </tr>
                    </tbody>
                  </S.GeneralInfoTable>
                </BaseCol>
                <BaseCol xs={24} lg={8}>
                  <S.GeneralInfoTable>
                    <tbody>
                      <tr>
                        <th style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>Quantity:</th>
                        <td>{item.quantity}</td>
                      </tr>
                    </tbody>
                  </S.GeneralInfoTable>
                </BaseCol>
                <BaseCol span={24}>
                  <S.GeneralInfoTable>
                    <tbody>
                      <tr>
                        <th style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>Note:</th>
                        <td>{item.note || '-'}</td>
                      </tr>
                    </tbody>
                  </S.GeneralInfoTable>
                </BaseCol>
                {item?.positions.length > 0 && (
                  <BaseCol xs={24}>
                    <S.Space></S.Space>
                    <TitleText level={2}>Positions</TitleText>
                    <BaseRow gutter={[20, 12]}>
                      {item?.positions.map((position, index) => {
                        return (
                          <BaseCol xs={24} key={index}>
                            <BaseRow gutter={[20, 4]}>
                              <BaseCol xs={12}>Position: {getPositionText(position.position)}</BaseCol>
                              <BaseCol xs={12}>Type: {getTypeText(position.type)}</BaseCol>
                              <S.PositionRow xs={24} lg={12}>
                                <S.PositionRowText>Design:</S.PositionRowText>
                                {position.designOriginal ? (
                                  <>
                                    <BaseTooltip title="Open the seller's design file">
                                      <BaseButton
                                        size="small"
                                        type="primary"
                                        href={position.designOriginal}
                                        target='"_blank"'
                                      >
                                        <EyeOutlined style={{ lineHeight: 0 }} />
                                      </BaseButton>
                                    </BaseTooltip>
                                    <BaseTooltip title={tooltipTextDesign}>
                                      <BaseButton
                                        size="small"
                                        severity="success"
                                        onClick={() => {
                                          navigator.clipboard.writeText(position.designOriginal || '');
                                          setTooltipTextDesign('Copied');
                                        }}
                                        onMouseLeave={() => setTooltipTextDesign("Copy the seller's design file")}
                                      >
                                        <CopyOutlined style={{ lineHeight: 0 }} />
                                      </BaseButton>
                                    </BaseTooltip>
                                  </>
                                ) : (
                                  '-'
                                )}
                                {!isSeller && isUpload && (
                                  <BaseTooltip title="Upload the final design file">
                                    <BaseButton
                                      size="small"
                                      severity={position.design ? 'warning' : 'error'}
                                      onClick={() => {
                                        const detail = {
                                          orderUuid: item.orderUuid,
                                          productSku: item.productSku,
                                          position: position,
                                          size: item.size,
                                          color: item.color,
                                        };
                                        getReplaceLink(detail).then((res) => {
                                          setReplaceLink(res);
                                          setModalEditType(false);
                                          setModalEditDesignOpenDetail(detail);
                                          setModalEditpOpen(true);
                                          setOrderDetailUuid(item.uuid);
                                        });
                                      }}
                                    >
                                      <UploadOutlined style={{ lineHeight: 0 }} />
                                    </BaseButton>
                                  </BaseTooltip>
                                )}
                                {position.design && (
                                  <>
                                    <BaseTooltip title="Open the finnal design file">
                                      <BaseButton size="small" type="primary" href={position.design} target='"_blank"'>
                                        <GlobalOutlined style={{ lineHeight: 0 }} />
                                      </BaseButton>
                                    </BaseTooltip>
                                  </>
                                )}
                              </S.PositionRow>
                              <S.PositionRow xs={24} lg={12}>
                                <S.PositionRowText>Mockup:</S.PositionRowText>
                                {position.mockupLinkOriginal ? (
                                  <>
                                    <BaseTooltip title="Open the seller's mockup file">
                                      <BaseButton
                                        size="small"
                                        type="primary"
                                        href={position.mockupLinkOriginal}
                                        target='"_blank"'
                                      >
                                        <EyeOutlined style={{ lineHeight: 0 }} />
                                      </BaseButton>
                                    </BaseTooltip>
                                    <BaseTooltip title={tooltipTextMokcup}>
                                      <BaseButton
                                        size="small"
                                        severity="success"
                                        onClick={() => {
                                          navigator.clipboard.writeText(position.mockupLinkOriginal || '');
                                          setTooltipTextMokcup('Copied');
                                        }}
                                        onMouseLeave={() => setTooltipTextMokcup("Copy the seller's mockup file")}
                                      >
                                        <CopyOutlined style={{ lineHeight: 0 }} />
                                      </BaseButton>
                                    </BaseTooltip>
                                  </>
                                ) : (
                                  '-'
                                )}
                                {!isSeller && isUpload && (
                                  <>
                                    <BaseTooltip title="Upload the final mockup file">
                                      <BaseButton
                                        size="small"
                                        severity={position.mockupLink ? 'warning' : 'error'}
                                        onClick={() => {
                                          const detail = {
                                            orderUuid: item.orderUuid,
                                            productSku: item.productSku,
                                            position: position,
                                            size: item.size,
                                            color: item.color,
                                          };
                                          getReplaceLink(detail).then((res) => {
                                            setReplaceLink(res);
                                            setModalEditType(true);
                                            setModalEditDesignOpenDetail(detail);
                                            setModalEditpOpen(true);
                                            setOrderDetailUuid(item.uuid);
                                          });
                                        }}
                                      >
                                        <UploadOutlined style={{ lineHeight: 0 }} />
                                      </BaseButton>
                                    </BaseTooltip>
                                  </>
                                )}
                                {position.mockupLink && (
                                  <>
                                    <BaseTooltip title="Open the finnal mockup file">
                                      <BaseButton
                                        size="small"
                                        type="primary"
                                        href={position.mockupLink}
                                        target='"_blank"'
                                      >
                                        <GlobalOutlined style={{ lineHeight: 0 }} />
                                      </BaseButton>
                                    </BaseTooltip>
                                  </>
                                )}
                              </S.PositionRow>
                            </BaseRow>
                          </BaseCol>
                        );
                      })}
                    </BaseRow>
                  </BaseCol>
                )}
              </BaseRow>
            </BaseCard>
          </BaseCol>
        );
      })}
      <ModalEditMockup
        handleSubmit={handleSubmit}
        isMockup={modalEditType}
        detail={modalEditDesignOpenDetail}
        isOpen={isModalEditOpen}
        replaceLink={replaceLink}
        onOpenChange={handleModalEditDesignOpen}
        orderDetailUuid={orderDetailUuid}
      />
    </BaseRow>
  );
}

export default ProductOrdersDetail;
