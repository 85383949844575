import React, { useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import {
  BalanceAdmin,
  BaseGRCluster,
  Customers,
  DashboardPageProps,
  Orders,
  Pending,
  Refunds,
  UnsolvedTickets,
} from '@app/components/nft-dashboard/Balance/Balance';
import * as S from './DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useTranslation } from 'react-i18next';
import { RecommendationProducts } from '@app/components/nft-dashboard/recommendationProducts/RecommendationProducts';
import { OverviewUser, getOverviewUser } from '@app/api/user.api';
import useCurrentRole from '@app/hooks/useCurrentRole';
import { CardsTitleStyle, SelectStyle, ViewIconStyle, WrapperCardsStyle, WrapperFilter } from './DashboardPage.styles';
import { CurrencyTypeEnum } from '@app/interfaces/interfaces';
import { formatNumberWithCommas, getCurrencyPrice } from '@app/utils/utils';
import { ReactComponent as ViewIcon } from '@app/assets/icons/view.svg';
import { Select } from 'antd';
import ChartsModal from '@app/components/nft-dashboard/Charts';

const options = [
  {
    value: 'allTime',
    label: 'All time',
  },
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: 'lastDay',
    label: 'Last day',
  },
  {
    value: 'last7Days',
    label: 'Last 7 days',
  },
  {
    value: 'thisMonth',
    label: 'This month',
  },
  {
    value: 'lastMonth',
    label: 'Last month',
  },
  {
    value: 'last30Days',
    label: 'Last 30 days',
  },
  {
    value: 'last3Moth',
    label: 'Last 3 months',
  },
  {
    value: 'customRange',
    label: 'Custom Range',
  },
];

const innitUserOverview: OverviewUser = {
  orders: 0,
  sales: 0,
  customers: 0,
  refunds: 0,
  pending: 0,
  confirmed: 0,
  inTroduction: 0,
  shipped: 0,
  accepted: 0,
  inTransit: 0,
  delivered: 0,
  cancel: 0,
  productionTime: 0,
  shippingTime: 0,
  //
  unsolvedTickets: 0,
  balance: 0,
  tobePaidAmount: 0,
};

const DashboardPage: React.FC = () => {
  const { isAdmin, isFactory, isSeller, isSupport } = useCurrentRole();

  const { t } = useTranslation();
  const [userOverview, setUserOverview] = useState(innitUserOverview);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getOverviewUser();
        setUserOverview(data);
      } catch (error) {
        console.log('error', error);
      }
    };

    fetch();
  }, [isAdmin, isFactory, isSeller, isSupport]);

  if (isFactory || isSupport)
    return (
      <>
        <PageTitle>{t('common.dashboard')}</PageTitle>

        {isSupport && (
          <>
            <BaseRow gutter={[20, 24]}>
              <BaseCol xl={6} lg={8} md={8} sm={12}>
                <BaseGRCluster title="Orders" dataShow={userOverview?.orders || 0} />
              </BaseCol>

              <BaseCol xl={6} lg={8} md={8} sm={12}>
                <BaseGRCluster title="Refund" dataShow={userOverview?.refunds || 0} />
              </BaseCol>

              <BaseCol xl={6} lg={8} md={8} sm={12}>
                <BaseGRCluster title="Pending" dataShow={userOverview?.pending || 0} />
              </BaseCol>

              <BaseCol xl={6} lg={8} md={8} sm={12}>
                <BaseGRCluster title="Unsolved Tickets" dataShow={userOverview?.unsolvedTickets || 0} />
              </BaseCol>
            </BaseRow>
          </>
        )}

        {isFactory && (
          <>
            <BaseRow gutter={[20, 24]}>
              <BaseCol xl={6} lg={8} md={8} sm={12}>
                <BaseGRCluster title="Orders" dataShow={userOverview?.orders || 0} />
              </BaseCol>

              <BaseCol xl={6} lg={8} md={8} sm={12}>
                <BaseGRCluster title="Pending" dataShow={userOverview?.pending || 0} />
              </BaseCol>

              <BaseCol xl={6} lg={8} md={8} sm={12}>
                <BaseGRCluster title="Unsolved Tickets" dataShow={userOverview?.unsolvedTickets || 0} />
              </BaseCol>
            </BaseRow>
          </>
        )}
      </>
    );

  // Wait: on phone will do later
  // const mobileAndTabletLayout = (
  //   <>
  //     {!isSeller ? (
  //       <>
  //         <BaseRow gutter={[20, 24]}>{/* <BaseCol span={24}>{cards}</BaseCol> */}</BaseRow>
  //       </>
  //     ) : (
  //       <BaseRow gutter={[20, 24]}>
  //         <BaseCol>
  //           {/* <div id="overview">{cards}</div> */}

  //           {mobileOnly && (
  //             <div id="balance">
  //               <Balance />
  //             </div>
  //           )}
  //         </BaseCol>

  //         <BaseCol span={24}>
  //           <RecommendationProducts />
  //         </BaseCol>
  //       </BaseRow>
  //     )}
  //   </>
  // );
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const handleChangeDate = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <>
      <PageTitle>{t('common.dashboard')}</PageTitle>

      <WrapperCardsStyle>
        <WrapperFilter>
          <ViewIconStyle onClick={() => setIsOpen(true)}>
            <ViewIcon width={24} height={24} />
            <span>View detailed stats</span>
          </ViewIconStyle>

          <SelectStyle>
            <Select
              defaultValue="all"
              style={{ width: 160 }}
              onChange={handleChange}
              dropdownMatchSelectWidth={false}
              size="small"
              listHeight={250}
              options={[
                {
                  value: 'all',
                  label: 'All Customers',
                },
                {
                  value: 'store',
                  label: 'Store',
                },
              ]}
            />
            <Select
              defaultValue="today"
              style={{ width: 160 }}
              onChange={handleChangeDate}
              size="small"
              listHeight={250}
              options={options}
            />
          </SelectStyle>
        </WrapperFilter>

        <div>
          <CardsTitleStyle>Sales Overview</CardsTitleStyle>

          <BaseRow gutter={[20, 24]}>
            <BaseCol xs={24} sm={12}>
              <BaseGRCluster title="Orders" dataShow={userOverview?.orders || 0} role={isAdmin ? 'admin' : 'seller'} />
            </BaseCol>

            <BaseCol xs={24} sm={12}>
              <BaseGRCluster
                title="Sales"
                dataShow={getCurrencyPrice(formatNumberWithCommas(750.43), CurrencyTypeEnum['USD'])} // TODO:
                role={isAdmin ? 'admin' : 'seller'}
              />
            </BaseCol>

            {isAdmin && (
              <>
                <BaseCol xs={24} sm={12}>
                  <BaseGRCluster title="Customers" dataShow={userOverview?.customers || 0} />
                </BaseCol>

                <BaseCol xs={24} sm={12}>
                  <BaseGRCluster title="Refund" dataShow={userOverview?.refunds || 0} />
                </BaseCol>
              </>
            )}
          </BaseRow>
        </div>

        <div>
          <CardsTitleStyle>Shipping Overview</CardsTitleStyle>

          <BaseRow gutter={[20, 24]}>
            <BaseCol xs={24} sm={12} lg={12} xl={6}>
              <BaseGRCluster title="Pending" dataShow={userOverview?.pending || 0} />
            </BaseCol>

            <BaseCol xs={24} sm={12} lg={12} xl={6}>
              {/* TODO: */}
              <BaseGRCluster title="Confirmed" dataShow={8} />
            </BaseCol>

            <BaseCol xs={24} sm={12} lg={12} xl={6}>
              {/* TODO:  */}
              <BaseGRCluster title="In Production" dataShow={9} />
            </BaseCol>

            <BaseCol xs={24} sm={12} lg={12} xl={6}>
              {/* TODO:  */}
              <BaseGRCluster title="Shipped" dataShow={12} />
            </BaseCol>

            {isAdmin && (
              <>
                <BaseCol xs={24} sm={12} lg={12} xl={6}>
                  {/* TODO: */}
                  <BaseGRCluster title="Accepted" dataShow={3} />
                </BaseCol>

                <BaseCol xs={24} sm={12} lg={12} xl={6}>
                  {/* TODO: */}
                  <BaseGRCluster title="In Transit" dataShow={8} />
                </BaseCol>

                <BaseCol xs={24} sm={12} lg={12} xl={6}>
                  {/* TODO:  */}
                  <BaseGRCluster title="Delivered" dataShow={9} />
                </BaseCol>

                <BaseCol xs={24} sm={12} lg={12} xl={6}>
                  {/* TODO:  */}
                  <BaseGRCluster title="Cancel" dataShow={12} />
                </BaseCol>
              </>
            )}
          </BaseRow>
        </div>

        <div>
          <CardsTitleStyle>Processing Overview</CardsTitleStyle>

          <BaseRow gutter={[20, 24]}>
            <BaseCol xs={24} sm={12}>
              {/* TODO:  */}
              <BaseGRCluster title="Production Time" dataShow={'3 days'} isFlexRow />
            </BaseCol>
            <BaseCol xs={24} sm={12}>
              {/* TODO:  */}
              <BaseGRCluster title="Shipping Time" dataShow={'5 days'} isFlexRow />
            </BaseCol>
          </BaseRow>
        </div>
      </WrapperCardsStyle>

      {isSeller && <RecommendationProducts />}

      {isOpen && <ChartsModal onCancel={() => setIsOpen(false)} />}
    </>
  );
};

export default DashboardPage;
