import {
  ReplyTicket,
  ReplyType,
  StatusSelect,
  UpdateStatusTicket,
  dataType,
  getTicketDetail,
} from '@app/api/ticket.api';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useMounted } from '@app/hooks/useMounted';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ChevronRightIcon } from '@app/assets/icons/chevron-right.svg';
import {
  BoxContent,
  BoxTime,
  ConversationContainer,
  ReplyContainer,
  TicketDetailHeader,
  TicketStatusContainer,
} from '@app/components/tickets/tickets.styles';
import { Avatar } from 'antd';
import { EllipsisOutlined, SendOutlined } from '@ant-design/icons';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { readUser } from '@app/services/localStorage.service';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import useCurrentRole from '@app/hooks/useCurrentRole';

type ConventionsType = {
  content: string;
  createdDate: string;
  owner: OwnerType;
};
type OwnerType = {
  avatar: string;
  fullName: string;
  uuid: string;
};

type TicketType = {
  uuid: string;
  conventions: ConventionsType[];
  owner: OwnerType;
  createdDate: string;
} & dataType;

const isOwner = (uuid: string) => {
  const user = readUser();
  if (user) {
    return user.uuid === uuid;
  }
  return false;
};

const DetailTicketPage: React.FC = () => {
  const { isMounted } = useMounted();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const endOfMessagesRef = useRef<null | HTMLDivElement>(null);
  const [tikestData, setTicketData] = useState<TicketType>();
  const [replyText, setReplyText] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const fecthData = useCallback(async () => {
    if (uuid) {
      try {
        setLoading(true);
        const res = await getTicketDetail(uuid);
        setTicketData({
          ...res,
          createdDate: new Date(res.createdDate).toLocaleString(),
        });
      } catch (error) {
        console.log(error);
        navigate('/tickets');
      } finally {
        setLoading(false);
      }
    } else {
      navigate('/tickets');
    }
  }, [uuid, navigate]);

  useEffect(() => {
    if (isMounted.current) {
      fecthData();
    }
  }, [isMounted, fecthData]);
  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [tikestData]);

  const handleReply = async () => {
    if (replyText) {
      const data: ReplyType = {
        ticketUuid: uuid as string,
        content: replyText,
      };
      try {
        await ReplyTicket(data);
        setReplyText('');
        fecthData();
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleReply();
    }
  };

  return (
    <>
      {tikestData && (
        <BaseSpin spinning={loading}>
          <BaseCard
            className="ticket-detail-card"
            padding={0}
            style={{ padding: '1rem', height: `calc(100vh - 200px)` }}
            title={<HeaderTitle ticketData={tikestData} onLoad={() => fecthData()} />}
          >
            <PageTitle>{tikestData.subject}</PageTitle>

            <ConversationContainer>
              <div className={`box-message ${isOwner(tikestData.owner.uuid) ? 'owner' : ''}`}>
                <Avatar src={tikestData.owner.avatar} />
                <div className="author">{tikestData.owner.fullName}</div>
                <BoxContent>
                  <div className="content">{tikestData.content}</div>
                  <BoxTime>{tikestData.createdDate}</BoxTime>
                </BoxContent>
              </div>
              {tikestData.conventions.map((item, index) => (
                <div key={index} className={`box-message ${isOwner(item.owner.uuid) ? 'owner' : ''}`}>
                  <Avatar src={item.owner.avatar} />
                  <div className="author">{item.owner.fullName}</div>
                  <BoxContent>
                    <div className="content">{item.content}</div>
                    <BoxTime>{new Date(item.createdDate).toLocaleString()}</BoxTime>
                  </BoxContent>
                </div>
              ))}
              <div ref={endOfMessagesRef} />
            </ConversationContainer>

            {tikestData.status !== 'CLOSED' && (
              <ReplyContainer>
                <BaseInput
                  onKeyDown={handleKeyPress}
                  onChange={(event) => setReplyText(event.target.value)}
                  value={replyText}
                  placeholder={'Aa'}
                  maxLength={900}
                />
                <SendOutlined onClick={handleReply} />
              </ReplyContainer>
            )}
          </BaseCard>
        </BaseSpin>
      )}
    </>
  );
};

export default DetailTicketPage;

type HeaderProps = {
  ticketData: TicketType;
  onLoad: () => void;
};
const HeaderTitle: React.FC<HeaderProps> = ({ ticketData, onLoad }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isAdmin, isSupport } = useCurrentRole();

  const handleChangeStatus = async (status: string) => {
    try {
      await UpdateStatusTicket(ticketData.uuid, status);
      onLoad();
    } catch (error) {
      console.log(error);
    }
  };
  const content = () => {
    return (
      <TicketStatusContainer>
        {StatusSelect.map((item, index) => (
          <p
            onClick={() => handleChangeStatus(item.value)}
            className={item.label === ticketData.status ? 'active' : 'inactive'}
            key={index}
          >
            {item.label}
          </p>
        ))}
      </TicketStatusContainer>
    );
  };

  return (
    <TicketDetailHeader>
      <div className="title">
        <Link className="cursor" to={'/tickets'}>
          {t('common.tickets')}
        </Link>
        <ChevronRightIcon />
        <span className="subject">{ticketData.subject}</span>
      </div>
      {(isAdmin || isSupport) && (
        <BasePopover placement="bottom" content={content} trigger="click">
          <EllipsisOutlined />
        </BasePopover>
      )}
    </TicketDetailHeader>
  );
};
