import React from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { Dropdown, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FullNameStyle, Text, WrapperStyle } from './ProfileDropdown.styles';

export const ProfileDropdown: React.FC = () => {
  const { isDesktop } = useResponsive();
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.user.user);

  const items: MenuProps['items'] = [
    {
      label: (
        <Text>
          <Link to="/profile">{t('profile.title')}</Link>
        </Text>
      ),
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <Text>
          <Link to="/logout">{t('header.logout')}</Link>
        </Text>
      ),
      key: '1',
    },
  ];

  return user ? (
    <Dropdown menu={{ items }} trigger={['click']}>
      <WrapperStyle>
        {isDesktop ? (
          <FullNameStyle>{user.fullName}</FullNameStyle>
        ) : (
          <BaseAvatar src={user.avatar} alt="User" shape="circle" size={30} />
        )}
      </WrapperStyle>
    </Dropdown>
  ) : null;
};
