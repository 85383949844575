import { DownOutlined } from '@ant-design/icons';
import { media } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';

export const Text = styled(BaseTypography.Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-size: 0.875rem;
  font-weight: 600;

  & > a {
    display: block;
  }

  @media only screen and ${media.md} {
    font-size: 1rem;
  }
`;

export const DownArrow = styled(DownOutlined)`
  color: var(--text-secondary-color);

  @media only screen and ${media.md} {
    color: var(--text-main-color);
  }
`;

export const FullNameStyle = styled.span`
  color: var(--white);
  transition: color 0.3s ease-in-out;

  &:hover {
    color: var(--primary2-color);
  }
`;

export const WrapperStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
