import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/logo-full.png';
import {
  DesktopHeaderStyle,
  HeaderBalanceStyle,
  HeaderCreateOrderBtn,
  HeaderNotificationStyle,
  HeaderRightStyle,
  HeaderTopUpBtn,
} from '../Header.styles';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { formatNumberWithCommas, getCurrencyPrice } from '@app/utils/utils';
import { CurrencyTypeEnum } from '@app/interfaces/interfaces';
import { TopUpBalanceModal } from '@app/components/nft-dashboard/Balance/components/TopUpBalanceModal/TopUpBalanceModal';
import { getPaymentChannels, PaymentChannelModel, topup } from '@app/api/topUp.api';
import { useTranslation } from 'react-i18next';
import { TopUpFormData } from '@app/components/nft-dashboard/Balance/interfaces/interfaces';
import { notificationController } from '@app/controllers/notificationController';
import { useResponsive } from '@app/hooks/useResponsive';
import { useAppSelector } from '@app/hooks/reduxHooks';

export const DesktopHeader1 = () => {
  const { t } = useTranslation();
  const { tabletOnly } = useResponsive();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [cards, setCards] = useState<PaymentChannelModel[]>([]);
  const [loading, setLoading] = useState(false);

  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    setLoading(true);
    getPaymentChannels('en')
      .then((res) => setCards(res))
      .finally(() => setLoading(false));
  }, []);

  const onFinish = (values: TopUpFormData) => {
    setLoading(true);
    topup({
      amount: values.amount,
      evdUrl: values.evidence.file.response.data,
      paymentChannel: values.paymentChannel,
      transactionId: values.transactionId,
    })
      .then(() => {
        setTimeout(() => {
          notificationController.success({ message: t('topup.success') });
          setModalOpen(false);
        }, 1000);
      })
      .finally(() => setLoading(false));
  };

  const price = getCurrencyPrice(formatNumberWithCommas(user?.walletBalance || 0), CurrencyTypeEnum['USD']);

  return (
    <>
      <DesktopHeaderStyle>
        <Link to="/">
          <img src={logo} alt="egfulfill" width={tabletOnly ? 150 : 176} />
        </Link>

        <HeaderRightStyle>
          <HeaderBalanceStyle>Balance: {price}</HeaderBalanceStyle>

          <HeaderTopUpBtn onClick={() => setModalOpen(true)}>Top Up</HeaderTopUpBtn>

          <HeaderCreateOrderBtn>Create Order</HeaderCreateOrderBtn>

          <HeaderNotificationStyle />

          <ProfileDropdown />
        </HeaderRightStyle>
      </DesktopHeaderStyle>

      <TopUpBalanceModal
        cards={cards}
        loading={loading}
        isOpen={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onFinish={onFinish}
      />
    </>
  );
};
