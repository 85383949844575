import axios from 'axios';
import { AxiosError } from 'axios';
import { clearLocalStorageWhenLogout, persistToken, readRemember, readToken } from '@app/services/localStorage.service';
import { ApiError } from './ApiError';

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  },
});
const checkAuthorization = (url: string) => {
  const urlNotAuthorization = ['login', 'register', 'forgotPassword'];
  const isNotAuthorization = urlNotAuthorization.some((item) => url.includes(item));
  return !isNotAuthorization;
};

const refreshToken = async () => {
  const res = await httpApi.get('/api/v1/auth/refresh-token');
  persistToken(res.data.accessToken);
};

httpApi.interceptors.request.use(
  async (config) => {
    if (checkAuthorization(config.url || '')) {
      const token = readToken();
      if (token) {
        config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
      }
    }

    return config;
  },
  (error) => Promise.reject(error),
);

httpApi.interceptors.response.use(
  (response) => response.data,
  async (error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        const isRemember = readRemember();
        console.log(!error.config.url?.includes('refresh-token'));
        if (!error.config.url?.includes('refresh-token') && !error.config.url?.includes('login') && isRemember) {
          try {
            await refreshToken();
            const token = readToken();
            if (token) {
              error.config.headers = { ...error.config.headers, Authorization: `Bearer ${token}` };
            }
            return httpApi.request(error.config);
          } catch (refreshError) {
            window.location.href = `/auth/login?callBack=${window.location.pathname}`;
            clearLocalStorageWhenLogout();
          }
        } else {
          window.location.href = `/auth/login?callBack=${window.location.pathname}`;
          clearLocalStorageWhenLogout();
        }
      }
      if (error.response.status === 404) {
        window.location.href = '/404';
      }
    }
    throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
  },
);

export interface ApiErrorData {
  message: string;
}

export interface Page<T> {
  content: T[];
  pageable: Pageable;
  last: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: any[];
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface Pageable {
  pageNumber: number;
  pageSize: number;
  sort: any[];
  offset: number;
  unpaged: boolean;
  paged: boolean;
}
