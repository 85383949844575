// GKC: 01J1RR3BMN8XMV65VSK6SHEW0W
import { Outlet, useNavigate } from 'react-router-dom';
import { Overlay } from '@app/components/common/Overlay/Overlay';
import { useEffect, useState } from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import { Header } from '@app/components/header/Header';
import MainSider from '../main/sider/MainSider/MainSider';
import { MainFooterStyle, MainStyle, RightMainStyle } from './BaseLayout.styles';
import { Layout } from 'antd';
import { References } from '@app/components/common/References/References';
import { clearLocalStorageWhenLogout, getRole, readToken } from '@app/services/localStorage.service';
import { getUser } from '@app/api/user.api';
import { initialState, setUser } from '@app/store/slices/userSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import LiveChat from '@app/components/common/LiveChat';

// define component
const BaseLayout: React.FC = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(true);
  const { isDesktop } = useResponsive();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // TODO: role
    const token = readToken();
    const role = getRole();

    console.log({ token, role });

    const fetchUserData = async () => {
      await getUser().then((data) => {
        const convertData = {
          uuid: data.userUuid,
          fullName: data.fullname,
          email: data.email,
          stores: data.stores,
          socials: data.socials,
          address: data.address,
          walletBalance: data.walletBalance,
          avatar: data.avatar,
        };

        dispatch(
          setUser({
            ...convertData,
            roles: role,
          }),
        );
      });
    };

    if (token && role) {
      fetchUserData();
    } else {
      clearLocalStorageWhenLogout();
      dispatch(setUser(initialState));
    }
  }, []);

  const toggleSidebarCollapsed = () => setSidebarCollapsed(!sidebarCollapsed);

  return (
    <Layout style={{ height: '100vh' }}>
      <Header toggleSider={toggleSidebarCollapsed} isSiderOpened={!sidebarCollapsed} />

      <MainStyle $isNotDesktop={!isDesktop}>
        <MainSider isCollapsed={sidebarCollapsed} setCollapsed={setSidebarCollapsed} />

        <RightMainStyle $isNotDesktop={!isDesktop}>
          <Outlet />

          <MainFooterStyle>
            <References />
          </MainFooterStyle>
        </RightMainStyle>
      </MainStyle>

      <LiveChat />

      {!isDesktop && <Overlay onClick={toggleSidebarCollapsed} show={!sidebarCollapsed} />}
    </Layout>
  );
};

export default BaseLayout;
