import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { FONT_FAMILY, FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import styled, { css } from 'styled-components';
import { ReactComponent as InformationIcon } from '@app/assets/icons/info.svg';

export const TitleText = styled.p``;

export const TitleBalanceText = styled.p`
  font-weight: bold;
`;

export const InfomationIconStyle = styled(InformationIcon)`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  cursor: help;
`;

export const WrapperTextStyle = styled.div<{ $isFlexRow?: boolean }>`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column-reverse;

  & p {
    margin-bottom: 0;
  }

  ${(props) =>
    props?.$isFlexRow &&
    css`
      flex-direction: row;
    `}
`;

export const SubtitleBalanceText = styled(BaseTypography.Text)`
  font-family: ${FONT_FAMILY.secondary};

  font-size: ${FONT_SIZE.xs};

  font-weight: ${FONT_WEIGHT.regular};

  color: var(--text-nft-light-color);
`;
export const CardFooter = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 3.125rem;
  padding: 0.7125rem 0;
  .grow {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: ${FONT_SIZE.xxs};
    color: var(--green);
    svg {
      padding-bottom: 0.25rem;
      height: 1rem;
      width: 1rem;
      path {
        fill: var(--green);
      }
    }
  }
  .reduce {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: ${FONT_SIZE.xxs};
    color: var(--red);
    svg {
      padding-top: 0.125rem;
      height: 1rem;
      width: 1rem;
      transform: rotate(180deg);
      path {
        fill: var(--red);
      }
    }
  }
`;
