import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '../TopUpPages/BillingPage.style';
import * as NewsStyle from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { References } from '@app/components/common/References/References';
import { Balance } from '@app/components/nft-dashboard/Balance/Balance';
import { ColumnsType } from 'antd/es/table';
import {
  PaymentChannelModel,
  TopUpTransactionModel,
  changeStatus,
  getPaymentChannels,
  search,
} from '@app/api/topUp.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Pagination } from '@app/api/table.api';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EyeTwoTone } from '@ant-design/icons';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { notificationController } from '@app/controllers/notificationController';
import { BaseAlert } from '@app/components/common/BaseAlert/BaseAlert';
import useCurrentRole from '@app/hooks/useCurrentRole';
import { TopUpDetailModal } from '../TopUpPages/components/TopUpDetailModal';
import { Wrapper } from './History.styles';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 20,
  total: 0,
};

export interface TopUpApproveFormData {
  uuid: string;
  status: string;
  rejectNote?: string;
}

interface DataType {
  uuid: string;
  type: string;
  message: string;
  user: string;
  date: Date;
}

const HistoryPage: React.FC = () => {
  const { isDesktop, isTablet, mobileOnly } = useResponsive();
  const { isAdmin } = useCurrentRole();
  const { isMounted } = useMounted();
  const { t } = useTranslation();
  const [needLoad, setNeedLoad] = useState<boolean>(true);
  const [valueSearch, setValueSearch] = useState<string>('');
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [detailOrderUuid, setDetailOrderUuid] = useState<string>('');
  const [cards, setCards] = useState<PaymentChannelModel[]>([]);
  const [loading, setLoading] = useState(false);

  const columns: ColumnsType<DataType> = [
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Message',
      dataIndex: 'message',
    },
    {
      title: 'User',
      dataIndex: 'user',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (data: Date) => {
        const content = data.toLocaleString();
        return <span>{content}</span>;
      },
    },
  ];

  const [tableData, setTableData] = useState<{
    data: DataType[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const dummyData = {
    size: 20,
    sort: [],
    totalElements: 21,
    totalPages: 2,
    number: 0,
    content: [
      {
        uuid: 'f05e6413-aabf-4de6-96e8-85a4abf21ea1',
        type: 'Import/Export',
        message: 'Order ...etc',
        user: 'tuankma98@gmail.com',
        date: new Date('2024-06-06T04:04:21.886067Z'),
      },
      {
        uuid: 'f05e6413-aabf-4de6-96e8-85a4abf21ea2',
        type: 'Transaction',
        message: '$ 1000 was add ...etc',
        user: 'tuankma198@gmail.com',
        date: new Date('2024-06-06T04:04:21.886067Z'),
      },
    ],
  };

  const fetch = useCallback(
    async (pagination: Pagination) => {
      try {
        const res = await dummyData;

        setTableData((tableData) => ({ ...tableData, loading: true }));

        if (isMounted.current) {
          setTableData((tableData) => ({
            ...tableData,
            loading: false,
            data: res.content.map((item: DataType, index: number) => {
              const newItem = {
                ...item,
                no: res.number * res.size + index + 1,
              };
              return newItem;
            }),
            pagination: {
              total: res.totalElements,
              current: res.number + 1,
              pageSize: pagination.pageSize || 10,
            },
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setTableData((tableData) => ({ ...tableData, loading: false }));
      }
    },
    [valueSearch, isMounted],
  );

  useEffect(() => {
    if (needLoad) {
      fetch(initialPagination);
      setNeedLoad(false);
    }
    getPaymentChannels('en').then((res) => setCards(res));
  }, [fetch, needLoad]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleModal = () => setModalOpen((open) => !open);

  useEffect(() => {
    // setLoading(true);
    getPaymentChannels('en').then((res) => setCards(res));
    // .finally(() => setLoading(false));
  }, []);

  const onFinish = (values: TopUpApproveFormData) => {
    setLoading(true);
    console.log(values);
    changeStatus(values.uuid, values.status, values.rejectNote)
      .then(() => {
        setTimeout(() => {
          notificationController.success({ message: 'Approve success!' });
          setModalOpen(false);
        }, 100);
      })
      .finally(() => {
        setLoading(false);
        setNeedLoad(true);
      });
  };

  return (
    <>
      <PageTitle>{t('common.history')}</PageTitle>

      <Wrapper>
        <BaseCard padding={'0'} style={{ backgroundColor: 'unset' }}>
          <BaseRow style={{ padding: '1.25rem' }}>
            <NewsStyle.InputWrapper style={{ marginBottom: '0' }}>
              <NewsStyle.SearchIcon />
              <NewsStyle.Input
                placeholder={t('header.search')}
                value={valueSearch}
                onChange={(event) => {
                  setValueSearch(event.target.value);
                }}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === 'Enter') {
                    fetch(initialPagination);
                  }
                }}
              />
            </NewsStyle.InputWrapper>
          </BaseRow>

          <BaseTable
            columns={columns}
            dataSource={tableData.data}
            pagination={tableData.pagination}
            loading={tableData.loading}
            onChange={handleTableChange}
            scroll={{ x: 1000 }}
            rowKey={(record) => record.uuid}
          />
        </BaseCard>

        <BaseModal width={500} open={isModalOpen} onCancel={handleModal} footer={null} destroyOnClose>
          <BaseSpin spinning={loading}>
            <TopUpDetailModal uuid={detailOrderUuid} cards={cards} loading={loading} onFinish={onFinish} />
          </BaseSpin>
        </BaseModal>
      </Wrapper>
    </>
  );
};

export default HistoryPage;
