import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Input } from '../orderDetail/Detail.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { detailOrder, detailOrderProduct } from '@app/api/order.api';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { useEffect, useState } from 'react';
import { TitleText } from '../Orders.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import * as S from '@app/components/orders/Orders.styles';
import { ColorItem } from '@app/components/catalog/conmon.styles';
import { FeesEditContainer } from './EditFeesProducts.styles';

type formProps = {
  initData?: detailOrder;
};

function EditFeesProducts({ initData }: formProps) {
  const [initFormData, setInitFormData] = useState<detailOrder>();

  useEffect(() => {
    if (initData) {
      console.log(initData);
      setInitFormData(initData);
    }
  }, [initFormData, initData]);
  return (
    <>
      {initFormData?.products.map((product: detailOrderProduct, index: number) => (
        <FeesEditContainer key={index}>
          <BaseRow>
            <TitleText level={1}>
              <a href={`/catalog/product/detail-product/${product.productUuid}`} target='"_blank"'>
                {`${(index + 1).toString()}. ${product.name} - ${product.productSku}`}
              </a>
            </TitleText>
            <BaseRow style={{ width: '100%' }}>
              <BaseCol xs={24} lg={8}>
                <S.GeneralInfoTable>
                  <tbody>
                    <tr>
                      <th style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>Size:</th>
                      <td>{product.size}</td>
                    </tr>
                  </tbody>
                </S.GeneralInfoTable>
              </BaseCol>
              <BaseCol xs={24} lg={8}>
                <S.GeneralInfoTable>
                  <tbody>
                    <tr>
                      <th>Color:</th>
                      <td>
                        <ColorItem
                          key={product.color.hex}
                          className="item"
                          style={{ '--color': product.color.hex } as React.CSSProperties}
                        >
                          <span className="color-preview"></span>
                          {product.color.name}
                        </ColorItem>
                      </td>
                    </tr>
                  </tbody>
                </S.GeneralInfoTable>
              </BaseCol>
              <BaseCol xs={24} lg={8}>
                <S.GeneralInfoTable>
                  <tbody>
                    <tr>
                      <th style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>Quantity:</th>
                      <td>{product.quantity}</td>
                    </tr>
                  </tbody>
                </S.GeneralInfoTable>
              </BaseCol>
            </BaseRow>
          </BaseRow>
          <BaseRow style={{ width: 'calc(100% + 20px)', marginTop: '0.5rem' }} wrap={false} gutter={20}>
            <BaseCol span={12}>
              <BaseForm.Item
                label="Shipping Fees"
                name={['products', index, 'orderDetailUuid']}
                rules={[{ required: true }]}
                initialValue={product.uuid}
                hidden
              >
                <Input />
              </BaseForm.Item>
              <BaseForm.Item
                label="Shipping Fees"
                name={['products', index, 'shippingFees']}
                rules={[{ required: true }]}
                initialValue={product.shippingFee}
              >
                <InputNumber block={true} min={0} type="number" />
              </BaseForm.Item>
            </BaseCol>
            <BaseCol span={12}>
              <BaseForm.Item
                label="Extra Fees"
                name={['products', index, 'extraFees']}
                rules={[{ required: true }]}
                initialValue={product.extraFee}
              >
                <InputNumber block={true} min={0} type="number"></InputNumber>
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
        </FeesEditContainer>
      ))}
    </>
  );
}

export default EditFeesProducts;
